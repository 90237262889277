.homes__connect {
    height: auto; 
    margin: 80px 250px;
    display: flex;
    justify-content: center;
}

.homes__a {
    position: relative;
    width: 700px;
    height: 400px; /*随屏幕变化改成auto*/
    border: #fff 10px solid;
    background-color: var(--first-color-lighter);
    border-radius: 20px;
    overflow: hidden;
    display: flex;
}

.homes__b {
    position: absolute;
    width: 200px;
    height: 300px;
    transition: 1s;
    margin: 75px 50px;
    z-index: 1000;
}

.homes__b a{
    text-decoration: none;
    color:var(--third-color);

    font: 900 28px '';
}

.homes__b h2 {
    transition: .5s 1s;
    opacity: 0;
    color: var(--second-color-darker);
}

.homes__b span {
    transition: .5s 1s;
    color:var(--third-color-lighter);
    font: 500 15px '';
    position: absolute;
    top:70px;
}

.homes__c {
    position:absolute;

    top: -130px; 
    right: -240px;

}
.homes__d, .homes__e {
    position: absolute;
    transform: rotateZ(220deg) translate(0,0);
    right: calc(var(--i)*100px);
    width: calc(var(--w)*40px);
    border-radius: 100px;
    height: 500px;
    overflow: hidden;
    background-color: antiquewhite;

    transition:0.5s 0.5s;

}
.homes__d:nth-child(2) {
    background:rgb(211, 143, 166);
}
.homes__e {
    left: -470px;
    top:-140px;
    width: 70px;
    background-color: aquamarine;
}
.homes__a:hover .homes__c div {
    transition: .5s calc(var(--i)*.1s);
    transform: rotateZ(220deg) translate(-200px,400px);
}
.homes__a:hover .homes__b {
    transition:1s 0.5s;
    left:370px;
}
.homes__a:hover .homes__b span{
    transition:1s 0.5s;
    top: 145px
}
.homes__a:hover .homes__b h2{
    transition:1s 0.5s;
    opacity: 1;
}
.homes__f {
    width: 250px;
    height: 250px;
    position: absolute;
    background-image: url("goast.png");
    background-size: cover;
    margin: 70px;
    opacity: 0;
    transition: .5s;
}
.homes__a:hover .homes__f {
    transition: 1s 1.3s;
    opacity: 1;
}