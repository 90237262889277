.header {
  width: 100%;
  padding: 20px 0;
  text-align: center;
  z-index: 1000;
}
.header_logo {
  width:250px;
  margin:20px 0;
  cursor: pointer;
}
.header_ul {
  background: #000;
  width :100%;
  margin-top: 10px;
}
.header_ul li {
  display: inline-block;
  list-style: none;
  margin: 20px 50px;
  color: #fff;
  cursor: pointer;
  position: relative;
}
.header_ul li:hover{
  color: var(--first-color-lighter);
}
.header.sticky {
  position:fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  padding: 10px 8%;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: padding 1s;
}
.header.sticky ul {
  width: auto;
}
.header_logo.sticky {

  width:200px;
}

.header-span {
  &:before, &:after {
    content: '';
    position: absolute;
    width: 0%;
    height: 3px;
    bottom: -2px;
    margin-top: -0.5px;
    background: #fff;
  }

  &:before {
    left: -2.5px;
  }

  &:after {
    right: 2.5px;
    background: #fff;
    transition: width 0.8s cubic-bezier(0.22, 0.61, 0.36, 1);
  }

  &:hover {
    &:before {
      background: #fff;
      width: 110%;
      transition: width 0.5s cubic-bezier(0.22, 0.61, 0.36, 1);
    }

    &:after {
      background: transparent;
      width: 100%;
      transition: 0s;
    }
  }
}