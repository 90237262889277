/* 联系 */
.contact .container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.contact .content {
  display: flex;

  align-items: center;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  gap: 50px;
  /*justify-content: space-between;*/
}

.contact-left h2 {
  font-size: 2.1em;
  font-weight: 800;
  margin-bottom: 40px;
}

.contact-list li {
  margin-bottom: 40px;
}

.contact-list li i {
  color: var(--first-color);
  font-size: 2rem;
  margin-right: 5px;
}

.contact-list li h3 {
  font-size: 1.3em;
  font-weight: 600;
  margin-bottom: 10px;
}

.contact-list li span {
  margin-left: 40px;
}

.contact-list li span a {
  color: var(--second-color);
}

.contact-right p {
  color: var(--forth-color);
  font-size: 1.6em;
}

.contact-right p span {
  color: var(--forth-color-lighter);
  font-weight: 700;
}

.contact-form input,
.contact-form textarea {
  border: none;
  color: var(--forth-color-lighter);
  background: var(--third-color-lighter);
  font-size: var(--normal-font-size);
  margin-bottom: 20px;
  padding: 15px 40px 40px 20px;
}

.contact-form textarea {
  width: 100%;
  resize: none;
}

::placeholder {
  color: var(--body-color);
}

.contact-form .first-row input {
  width: 100%;
}

.contact-form .second-row {
  display: flex;
  justify-content: space-between;
}

.contact-form .second-row input {
  width: 48%;
}

.contact-form .contact-btn {
  border: none;
  margin-top: 0;
  border-radius: 10px;
  cursor: pointer;
  background-color: var(--first-color);
}
@media screen and (max-width: 520px) {
  .contact .content {
    flex-direction: column;
  }
}
