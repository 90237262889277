
.learn-more-btn {
    cursor: pointer;
    display: inline-flex;
    align-items: center; /* 确保 i 元素在按钮内垂直居中 */
    color:var(--third-color);
    margin-top: 5px;
    font-weight: var(--font-semi-bold);
}

.learn-more-btn i {
    display: inline-block;
    transition: .3s ease;
}

/* 当悬停在按钮时，i 元素移动 */
.learn-more-btn:hover i {
    transform: translateX(10px);
}

.service-modal {
z-index: 999999;
position: fixed;
width: 100%;
height: 100vh;
top: 0;
left: 0;

backdrop-filter: blur(10px);

visibility: hidden;
opacity: 0;
transition: .3s ease;
}

.service-modal.active {
visibility: visible;
opacity: 1;
}

.service-modal-body {
position: relative;
background: var(--third-color);
max-width: 600px;
margin: 20px;
padding: 40px;
border-radius: 10px;
box-shadow: var(--box-shadow);
transform: translateY(-50px);
transition: .5s ease;
}

.service-modal.active .service-modal-body {
transform: translateY(0);
}

.service-modal-body .modal-close-btn {
position: absolute;
top: 0;
right: 0;
margin: 20px;
cursor: pointer;
}

.service-modal-body h3 {
font-size: 2em;
}


.service-modal-body h4 {
font-size: 1.3em;
font-weight: 600;
margin: 15px 0 10px;
}


.service-modal-body ul li {
margin-top: 15px;
}