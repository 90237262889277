.home-others {
    height: auto; 
    margin: 10px 80px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-others-contianer {
    position: relative;
    width: 1200px;
    height: 400px; 
}
.home-others-contianer p{
    position: relative;
    margin-bottom: 10px;

    font-size: 22px;

    color: #424242;
  }
    

  .home-others-gallery-wrap {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    position: absolute;
  }
  
  .home-others-item {
    flex: 1;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: none;
    transition: flex 0.8s ease;
    
    &:hover{
      flex: 7;
    }
  }
  
  .item-1 { 
    background-image: url("../assets/img/catPhotos/cat1.jpg");
  }
  
  .item-2 { 
    background-image: url("../assets/img/catPhotos/cat2.jpg");
  }
  
  .item-3 { 
    background-image: url("../assets/img/catPhotos/cat3.jpg");
  }
  
  .item-4 { 
    background-image: url("../assets/img/catPhotos/cat4.jpg");
  }
  
  .item-5 { 
    background-image: url("../assets/img/catPhotos/cat5.jpg");
  }