/* 技能 */
.skills .container .content {
    width: 100%;
}

.skills-description {
    max-width: 1150px;
    margin-bottom: 50px;
}

.skills-description h3 {
    font-size: 2em;
    margin-bottom: 5px;
}

.skills-info {
    max-width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    margin: 0 auto;
}

.skills-info h4 {
    margin-bottom: 20px;
}

.skills-info h4 label {
    background: var(--forth-color);
    color: var(--first-color-lighter);
    font-size: var(--normal-font-size);
    font-weight: 400;
    padding: 5px 15px;
    border-radius: 5px;
}

.education-all {
    margin-bottom: 80px;
}

.edu-list .item {
    background: var(--first-color-lighter);
    box-shadow: var(--box-shadow);
    border-bottom: 3px solid var(--first-color-darker);
    padding: 20px;
    margin-top: 15px;
    border-radius: 6px;
    transition: .3s ease;
}

.edu-list .item .year {
    font-size: var(--small-font-size);
    margin-bottom: 5px;
}

.edu-list .item p {
    color: var(--second-color-darker);
    font-size: var(--small-font-size);
}

.edu-list .item p span {
    color: var(--second-color-darker);
    font-size: var(--normal-font-size);
    font-weight: 500;
}

.bar {
    background-color: var(--third-color-lighter);
    box-shadow: var(--box-shadow);
    margin-bottom: 10px;
    padding: 20px;
    border-radius: 6px;
    transition: .3s ease;
}

.bar .info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
    font-size: var(--small-font-size);
    color:var(--first-color-lighter);
}

.bar .info span {
    font-weight: 500;
}

.bar .line {
    position: relative;
    width: 100%;
    height: 7px;
    background: var(--second-color-darker);
    border-radius: 2px;
}

.bar .line::before {
    content: '';
    position: absolute;
    height: 100%;
    background-color: var(--first-color);
    top: 0;
    left: 0;
    width: 95%;
    border-radius: 2px;
}

.bar .html::before {
    width: 95%;
}

.bar .css::before {
    width: 85%;
}

.bar .javascript::before {
    width: 80%;
}

.bar .jquery::before {
    width: 80%;
}

.bar .java::before {
    width: 75%;
}

.experience-card {
    background: var(--first-color-lighter);
    border-bottom: 3px solid var(--first-color-darker);
    padding: 35px;
    border-radius: 6px;
    box-shadow: var(--box-shadow);
    transition: .3s ease;
    color:var(--second-color-darker);
}

.experience-card .upper {
    line-height: 30px;
}

.experience-card h3 {
    font-size: 1.3em;
    font-weight: 700;
}

.experience-card h5 {
    font-size: var(--small-font-size);
    font-weight: 500;
    font-style: italic;
}

.experience-card span {
    color: var(--third-color);

}

.experience-card .hr {
    width: 100%;
    height: 2px;
    background-color: var(--line-color);
    margin: 10px 0 22px;

}

.edu-list .item:hover,
.bar:hover,
.experience-card:hover {
    transform: scale(1.03);
}


@media screen and (max-width: 1070px) {

    /* 交往 */
    .get-in-touch .content-card {
        display: grid;
        width: 100%;
    }

    /* 联系 */
    .contact .content {
        flex-direction: column;
        font-size: 0.9rem;
    }

    .contact .contact-left {
        margin-bottom: 40px;
    }

    .contact-form .second-row {
        flex-direction: column;
    }

    .contact-form .second-row input {
        width: 100%;
    }
}

